import { Service } from '@wix/ambassador-bookings-services-v2-service/types';
import { queryServices } from '@wix/ambassador-bookings-services-v2-service/http';
import { HttpClient } from '@wix/http-client';
import { createHeaders } from '@wix/headers';

export const SERVICES_PAGE_SIZE = 100;

export async function getWixBookingsServices(
  signedInstance: string,
): Promise<Service[]> {
  try {
    let allServices: Service[] = [];
    let cursor: string | null | undefined;
    const headers = createHeaders({
      signedInstance,
    });
    const httpClient = new HttpClient({
      headers,
    });
    do {
      const {
        data: { pagingMetadata, services },
      } = await httpClient.request(
        queryServices({
          query: {
            cursorPaging: { limit: SERVICES_PAGE_SIZE, cursor },
          },
        }),
      );
      allServices = [...allServices, ...(services || [])];
      cursor = pagingMetadata?.cursors?.next;
    } while (cursor);
    return allServices;
  } catch (e) {
    // TODO: add sentry
    console.error(e);
    return [];
  }
}
