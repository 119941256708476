import { Service } from '@wix/ambassador-bookings-services-v2-service/types';
import { getService } from '@wix/ambassador-bookings-services-v2-service/http';
import { HttpClient } from '@wix/http-client';
import { createHeaders } from '@wix/headers';

export async function getWixBookingsServiceById(
  signedInstance: string,
  serviceId: string,
): Promise<Service | undefined> {
  try {
    const headers = createHeaders({
      signedInstance,
    });
    const httpClient = new HttpClient({
      headers,
    });

    const {
      data: { service },
    } = await httpClient.request(getService({ serviceId }));
    if (!service) {
      return;
    }

    return service;
  } catch (e) {
    // TODO: add sentry
    console.error(e);
    return;
  }
}
