import { updateService } from '@wix/ambassador-bookings-services-v2-service/http';
import {
  UpdateServiceResponse,
  MediaItem,
} from '@wix/ambassador-bookings-services-v2-service/types';
import { HttpClient } from '@wix/http-client';
import { createHeaders } from '@wix/headers';

export async function setWixBookingsServiceInfoMediaImages(
  signedInstance: string,
  id: string,
  items: MediaItem[],
  revision: string,
): Promise<UpdateServiceResponse> {
  try {
    const headers = createHeaders({
      signedInstance,
    });
    const httpClient = new HttpClient({
      headers,
    });

    const { data } = await httpClient.request(
      updateService({
        service: {
          id,
          media: {
            items,
          },
          revision,
        },
        mask: ['media.items'],
      }),
    );
    return data;
  } catch (e) {
    // TODO: add sentry
    console.error(e);
    return {};
  }
}
