import type * as Types from '../../../../../../__generated__/schemaTypes';

import { gql } from '@wix/wixstores-graphql-schema-node/dist/src/helpers/fakeGqlTag';
export type GetProductsListQueryVariables = Types.Exact<{
  offset: Types.Maybe<Types.Scalars['Int']>;
  limit: Types.Maybe<Types.Scalars['Int']>;
  filters: Types.Maybe<Types.ProductFilters>;
  sort: Types.Maybe<Types.ProductSort>;
}>;

export type GetProductsListQuery = {
  __typename?: 'WixStoresQueries';
  catalog: {
    __typename?: 'Catalog';
    products: {
      __typename?: 'ProductsQuery';
      list: Array<{
        __typename?: 'Product';
        id: string;
        name: string;
        media: Array<{
          __typename?: 'ProductMediaItem';
          altText: Types.Maybe<string>;
          id: Types.Maybe<string>;
          mediaType: string;
          title: string;
          url: string;
        }>;
      }>;
    };
  };
};

export const GetProductsListDocument = gql`
  query getProductsList(
    $offset: Int
    $limit: Int
    $filters: ProductFilters
    $sort: ProductSort
  ) {
    catalog {
      products(offset: $offset, limit: $limit, filters: $filters, sort: $sort) {
        list {
          id
          media {
            altText
            id
            mediaType
            title
            url
          }
          name
        }
      }
    }
  }
`;
