import { UpdateServiceResponse } from '@wix/ambassador-bookings-services-v2-service/types';
import { updateService } from '@wix/ambassador-bookings-services-v2-service/http';
import { HttpClient } from '@wix/http-client';
import { createHeaders } from '@wix/headers';

export async function setWixBookingsServiceInfoMediaCoverImageAltText(
  signedInstance: string,
  id: string,
  altText: string,
  revision: string,
): Promise<UpdateServiceResponse> {
  try {
    const headers = createHeaders({
      signedInstance,
    });
    const httpClient = new HttpClient({
      headers,
    });

    const { data } = await httpClient.request(
      updateService({
        service: {
          id,
          media: {
            coverMedia: {
              image: {
                altText,
              },
            },
          },
          revision,
        },
        mask: ['media.coverMedia.image.altText'],
      }),
    );
    return data;
  } catch (e) {
    // TODO: add sentry
    console.error(e);
    return {};
  }
}
