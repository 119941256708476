import { createWizardReport } from '@wix/ambassador-accessibility-v1-wizard-report/http';
import { CreateWizardReportRequest } from '@wix/ambassador-accessibility-v1-wizard-report/types';
import { createHeaders } from '@wix/headers';
import { createHttpClient } from '@wix/http-client';

export const createWizardMonitoringReport = async (
  appInstance: string,
  payload: CreateWizardReportRequest,
) => {
  const headers = createHeaders({
    signedInstance: appInstance,
  });
  const httpClient = createHttpClient({ headers });
  const { data } = await httpClient.request(createWizardReport(payload));

  return data;
};
